/**
 * promillelaskuri.js
 */

(function ($) {
    $(document).ready(function () {
        $('#weekly-consumption-counter .day').on('click', function () {
            $('#weekly-consumption-counter .day').removeClass('active');
            $(this).addClass('active');
        });

        $('#weekly-consumption-counter').on('click', '.alcohol .plus, .alcohol .minus', handleConsumptionClick);

        function handleConsumptionClick(event) {
            const isPlusButton = $(event.currentTarget).hasClass('plus');
            const valueChange = isPlusButton ? 1 : -0.5;

            let age = $('#age button.active').data('age');
            let gender = $('.gender.active').data('gender');
            let amount = $(event.currentTarget).closest('.alcohol').find('.amount');
            let value = parseFloat(amount.text()) || 0;
            let serving = $(event.currentTarget).closest('.alcohol').data('serving');
            if (value === 0 && valueChange === -1) {
                return;
            }
            value += valueChange;
            if (value < 0) {
                value = 0;
            }
            amount.text(value.toFixed(1))

            let servings = $('#weekly-consumption-counter .day.active .servings');
            let servingsValue = parseFloat(servings.text()) || 0;
            servingsValue += valueChange * serving;
            if (servingsValue < 0) {
                servingsValue = 0;
            }
            servings.text(servingsValue.toFixed(1));
            $(event.currentTarget).closest('.alcohol').data('totalServing', value * serving);
            $('#weekly-consumption-counter #weekly-total').text(parseFloat(getTotalWeeklyConsumption().toFixed(1)));
            $('#consumption-warning').text('');
            getWarning(getTotalWeeklyConsumption(), getMaxDailyConsumption(), age, gender);
        }

        $('#age button').on('click', function () {
            $('#age button').removeClass('active');
            $(this).addClass('active');
            let age = $('#age button.active').data('age');
            let gender = $('.gender.active').data('gender');
            $('#consumption-warning').text('');
            getWarning(getTotalWeeklyConsumption(), getMaxDailyConsumption(), age, gender);
        });

        function getMaxDailyConsumption() {
            let maxDaily = 0;
            $('#weekly-consumption-counter .day').each(function () {
                let servings = $(this).find('.servings').text();
                if (parseFloat(servings) > maxDaily) {
                    maxDaily = parseFloat(servings);
                }
            });
            return maxDaily;
        }

        function getTotalWeeklyConsumption() {
            let total = 0;
            $('#weekly-consumption-counter .day').each(function () {
                let servings = $(this).find('.servings').text();
                if (servings === '') {
                    servings = 0;
                }
                total += parseFloat(servings);
            });
            return total;
        }

        function getWarning(weeklyConsumption, dailyConsumption, age, gender) {
            const warning = $('#consumption-warning');
            warning.removeClass();
            if (age === 'young') {
                if (!weeklyConsumption) {
                    warning.html(bac_object.young_0);
                    warning.addClass('green');
                } else if (weeklyConsumption > 0 && weeklyConsumption < 3 && dailyConsumption < 3) {
                    warning.html(bac_object.young_1_3);
                    warning.addClass('yellow');
                } else {
                    warning.html(bac_object.young_over_3);
                    warning.addClass('red');
                }
            }
            if (age === 'middle' && gender === 'male') {
                if (!weeklyConsumption || (weeklyConsumption > 0 && weeklyConsumption < 13 && dailyConsumption < 7)) {
                    warning.html(bac_object.middle_daily_0_13);
                    warning.addClass('green');
                }else if (dailyConsumption >= 7) {
                    warning.html(bac_object.middle_daily_7);
                    warning.addClass('red');
                } else if (weeklyConsumption <= 13) {
                    warning.html(bac_object.middle_daily_7);
                    warning.addClass('yellow');
                } else if (weeklyConsumption > 13 && weeklyConsumption <= 23) {
                    warning.html(bac_object.middle_weekly_14_23);
                    warning.addClass('yellow');
                } else if (weeklyConsumption > 23) {
                    warning.html(bac_object.middle_weekly_23);
                    warning.addClass('red');
                }
            }
            if (age === 'middle' && gender === 'female') {
                if (!weeklyConsumption || (weeklyConsumption > 0 && weeklyConsumption < 6 && dailyConsumption < 5)) {
                    warning.html(bac_object.middle_daily_0_13);
                    warning.addClass('green');
                }else if (dailyConsumption >= 5) {
                    warning.html(bac_object.middle_daily_7);
                    warning.addClass('red');
                } else if (weeklyConsumption <= 7) {
                    warning.html(bac_object.middle_daily_7);
                    warning.addClass('yellow');
                } else if (weeklyConsumption > 7 && weeklyConsumption <= 11) {
                    warning.html(bac_object.middle_weekly_14_23);
                    warning.addClass('yellow');
                } else if (weeklyConsumption > 11) {
                    warning.html(bac_object.middle_weekly_23);
                    warning.addClass('red');
                }
            }
            if (age === 'old') {
                if (!weeklyConsumption || (weeklyConsumption > 0 && weeklyConsumption < 7 && dailyConsumption < 2)) {
                    warning.html(bac_object.old_daily_0_7);
                    warning.addClass('green');
                } else if (dailyConsumption > 2 && weeklyConsumption < 7) {
                    warning.html(bac_object.old_daily_2);
                    warning.addClass('yellow');
                } else if (weeklyConsumption > 7) {
                    warning.html(bac_object.old_weekly_7);
                    warning.addClass('red');
                }
            }
        }

        $('#weekly-consumption-counter #duplicate').on('click', function () {
            let customAlcohol = $('.custom').first();
            let newCustomAlcohol = customAlcohol.clone();
            newCustomAlcohol.find('.amount').text(0);
            $('.alcohols').children('.custom').last().after(newCustomAlcohol);
        });

        $('#clear').click(function() {
            location.reload();
        });
    });

})(jQuery);
